import React from "react";
import Immutable from "immutable";
import {Checkbox, CircularProgress, Dialog, IconButton, RaisedButton, TextField, Toggle} from "material-ui";
import ImmutableSelect from "js/components/immutable-select";

import {
  addZeroOption,
  defaultCurrencyMappings,
  defaultSalaryUnitMappings,
  hasFields,
  isValidCommissionPercentage,
  salaryUnitOptions,
  getAvailableAdditionalDataFields
} from "js/app-areas/onboarding/step-2/config-helpers";
import {indexBy} from "js/utils/collections";
import {productTiers} from "js/app-areas/onboarding/product-tiers";

const piiCategories = Immutable.fromJS([
  {value: "NOT_PII", label: "Not PII"},
  {value: "PII", label: "PII"}]);

const inferExtensionDateOptions = Immutable.fromJS([
  {value: "NONE", label: "None"},
  {value: "START_DATE", label: "Infer Start Date"},
  {value: "END_DATE", label: "Infer End Date"}]);

const handleCheckboxClick = (config, onChange, location, isMultiple) => {
  const isDisabled = config.getIn(location.push("isDisabled"));
  const updatedCheckboxField = config.setIn(location.push("isDisabled"), !isDisabled);
  if (!isDisabled) {
    onChange(updatedCheckboxField
        .setIn(location.push("value"), isMultiple ? Immutable.List() : null));
  } else {
    onChange(updatedCheckboxField);
  }
};

const handleAddCustomOption = (config, onChange, value, location, isMultiple) => {
  onChange(config
      .updateIn(location.push("customOptions"), Immutable.List(), current => current.push(value))
      .updateIn(
          location.push("value"),
          Immutable.List(),
          current => isMultiple ? current.push(value) : value));
};

const generateOnChange = (config, onChange, location) => {
  const onChangeValue = value => onChange(config.setIn(location.push("value"), Immutable.List(value)));
  const onChangeCheckbox = () => handleCheckboxClick(config, onChange, location, true);
  const onAddCustomOption = option => handleAddCustomOption(config, onChange, option, location, true);

  return [onChangeValue, onChangeCheckbox, onAddCustomOption];
};

const FieldWithFallbackHeader = () => <div style={{display: "flex"}}>
  <div style={{width: 300, margin: "0 15px 10px 135px"}}>Field</div>
  <div style={{width: 300}}>Fallback
    <i
        title={"Field to use if the main option is blank (optional)"}
        className={"fa fa-info-circle"}
        style={{display: "inline", marginLeft: 3, opacity: "70%"}} /></div>
</div>;

const PlacementForm = ({
  placementMetadataField,
  placementConfig,
  onUpdatePlacement,
  generateCurrencyDefaultText,
  currencyCodes,
  availableFields
}) => {

  const nameToField = placementMetadataField.reduce(
      (result, item) => result.set(item.get("name"), item),
      Immutable.Map());
  const placementFields = placementMetadataField.map(field => field.get("name"));
  const employmentTypes = nameToField
      .getIn(["employmentType", "options"], Immutable.List())
      .map(option => option.get("value"));
  const statusOptions = nameToField
      .getIn(["status", "options"], Immutable.List())
      .map(option => option.get("value"));

  const employmentTypesAvailableForPerm = employmentTypes
      .filter(type => !placementConfig
          .getIn(["employmentType", "contract", "value"], Immutable.List())
          .includes(type));
  const employmentTypesAvailableForContract = employmentTypes
      .filter(type => !placementConfig
          .getIn(["employmentType", "perm", "value"], Immutable.List())
          .includes(type));

  const remainingStatusOptions = statusOptions.filterNot(status =>
      placementConfig
          .getIn(["cube-status->types", "DID_NOT_START", "value"])
          .includes(status)
      || placementConfig
          .getIn(["cube-status->types", "AGREED", "value"])
          .includes(status));

  const onChangeCurrencyValue = value => onUpdatePlacement(placementConfig.setIn(
      ["currency", "field", "value"],
      value));
  const onChangeCurrencyCheckbox = () => handleCheckboxClick(
      placementConfig,
      onUpdatePlacement,
      Immutable.List(["currency", "field"]),
      false);

  const [onChangePermValue, onChangePermCheckbox, onAddPermCustomOption] = generateOnChange(
      placementConfig,
      onUpdatePlacement,
      Immutable.List(["employmentType", "perm"]));
  const [onChangeContractValue, onChangeContractCheckbox, onAddContractCustomOption] = generateOnChange(
      placementConfig,
      onUpdatePlacement,
      Immutable.List(["employmentType", "contract"]));

  const onChangeAgreedValue = value => onUpdatePlacement(placementConfig.setIn([
    "cube-status->types",
    "AGREED",
    "value"], Immutable.List(value)));
  const onAddAgreedCustomOption = option => handleAddCustomOption(
      placementConfig,
      onUpdatePlacement,
      option,
      Immutable.List(["cube-status->types", "AGREED"]),
      true);

  const onChangeDidNotStartValue = value => onUpdatePlacement(placementConfig.setIn([
    "cube-status->types",
    "DID_NOT_START",
    "value"], Immutable.List(value)));
  const onAddDidNotStartCustomOption = option => handleAddCustomOption(
      placementConfig,
      onUpdatePlacement,
      option,
      Immutable.List(["cube-status->types", "DID_NOT_START"]),
      true);

  const message = remainingStatusOptions.size > 0 && <div>
    {`Please note we could not automatically map ${remainingStatusOptions.size === 1
        ? "status"
        : "statuses"}: `}<b>{remainingStatusOptions.join(", ")}</b> - if you choose not to select a corresponding
    cube19 status these statuses will not be included in cube.
  </div>;

  const reportedMarginEnabled = placementConfig.getIn(["employmentType", "contract", "reported-margin", "value", "isToggled"]);

  const handleReportedMarginToggle = isToggled => {
    onUpdatePlacement(placementConfig
        .setIn(["employmentType", "contract", "reported-margin", "value", "isToggled"], isToggled)
    );
  };

  const hasScalingFactor = placementConfig.getIn(["placement-scaling-factor", "isToggled"]);
  const scalingFactor = placementConfig.getIn(["placement-scaling-factor", "value"]);

  const handleScalingFactorToggle = isToggled => {
    onUpdatePlacement(placementConfig
        .setIn(["placement-scaling-factor", "isToggled"], isToggled)
        .setIn(["placement-scaling-factor", "value"], null)
    );
  };

  const handleScalingFactorChange = scalingFactor => onUpdatePlacement(placementConfig
      .setIn(["placement-scaling-factor", "value"], scalingFactor));

  const isValidScalingFactor = scalingFactor => !!scalingFactor
      && !isNaN(scalingFactor)
      && scalingFactor > 0
      && scalingFactor <= 100;

  const handleSalaryUnitMappingChange = value => {
    onUpdatePlacement(placementConfig.setIn(
        ["employmentType", "contract", "salary-unit", "mappings", "value"],
        Immutable.List(value)));
  };

  const handleAddCustomSalaryUnit = option => handleAddCustomOption(
      placementConfig,
      onUpdatePlacement,
      option,
      Immutable.List(["employmentType", "contract", "salary-unit", "mappings"]),
      true);

  const handleCurrencyChange = value => {
    onUpdatePlacement(placementConfig.setIn([
      "currency",
      "crm-currency-to-code",
      "value"], Immutable.List(value)));
  };

  const handleAddCustomCurrency = option => handleAddCustomOption(
      placementConfig,
      onUpdatePlacement,
      option,
      Immutable.List(["currency", "crm-currency-to-code"]),
      true);

  const onChangeValueOrFallback = (location, value) => {
    onUpdatePlacement(placementConfig.setIn(location, value));
  };

  const usesCopyPlacement = placementConfig.getIn(["copy-placement", "field", "isToggled"]);

  const handleCopyPlacementToggle = isToggled => {
    onUpdatePlacement(placementConfig
        .setIn(["copy-placement", "field", "isToggled"], isToggled)
        .setIn(["copy-placement", "field", "value"], null)
    );
  };

  const handleCopyPlacementChange = (key, field) => onUpdatePlacement(placementConfig.setIn([
    "copy-placement",
    key,
    "value"], field));

  const handleUpdateAdditionalData = newAdditionalDataConfig => onUpdatePlacement(placementConfig.set(
      "additional-data",
      newAdditionalDataConfig));

  const handleUpdateCommissionMapping = newMappingConfig => onUpdatePlacement(placementConfig.set(
      "alternative-commission-mapping",
      newMappingConfig));
  return <div>
    <div style={{marginBottom: 20}}>
      <InputWithCheckbox
          fieldData={placementConfig.getIn(["currency", "field"])}
          options={getFieldOptionValueMaps(placementMetadataField)}
          hasDisableCheckbox={true}
          onChangeValue={onChangeCurrencyValue}
          onChangeCheckbox={onChangeCurrencyCheckbox}
          selectLabel="Placement Currency Field"
          checkboxLabel={generateCurrencyDefaultText("Placement")}
      />
      {!placementConfig.getIn(["currency", "field", "isDisabled"]) && <CurrencyCodeTranslationPicker
          fieldData={placementConfig.getIn(["currency", "crm-currency-to-code"])}
          selectLabel="Currency Code Mappings"
          onChangeValue={handleCurrencyChange}
          onAddCustomOption={handleAddCustomCurrency}
          currencyCodes={currencyCodes}
      />}
      {hasFields(availableFields, "Placement", "cube-status->types") && <div style={{marginTop: 30, marginBottom: 30}}>
        <div style={{marginBottom: 10}}>
          <b>Bullhorn to Cube19 Status Mapping</b>
        </div>
        <InputWithCheckbox
            fieldData={placementConfig.getIn(["cube-status->types", "AGREED"])}
            options={getOptionValueMaps(statusOptions.filter(status => !placementConfig.getIn([
              "cube-status->types",
              "DID_NOT_START", "value"]).includes(status)))}
            multiple={true}
            hasCustomOptions={true}
            onChangeValue={onChangeAgreedValue}
            onAddCustomOption={onAddAgreedCustomOption}
            selectLabel="Show in Placement Metrics by default:"
            customOptionLabel="Add A Custom Status:"
        />
        <InputWithCheckbox
            fieldData={placementConfig.getIn(["cube-status->types", "DID_NOT_START"])}
            options={getOptionValueMaps(statusOptions.filter(status => !placementConfig.getIn([
              "cube-status->types",
              "AGREED",
              "value"])
                .includes(status)))}
            multiple={true}
            hasCustomOptions={true}
            onChangeValue={onChangeDidNotStartValue}
            onAddCustomOption={onAddDidNotStartCustomOption}
            selectLabel="Don't show in Placement Metrics by default:"
            customOptionLabel="Add A Custom Status:"
        />
        {remainingStatusOptions.size > 0 && <Warning message={message} />}
      </div>}
      <div>
        <FieldWithFallbackHeader />
        {hasFields(availableFields, "Placement", "created-date") && <FieldPickerWithFallback
            options={getFieldOptionValueMaps(placementMetadataField)}
            config={placementConfig.get("created-date", Immutable.Map())}
            onChange={onChangeValueOrFallback}
            configLocation={["created-date"]}
            label="Created Date" />}
        {hasFields(availableFields, "Placement", "start-date") && <FieldPickerWithFallback
            options={getFieldOptionValueMaps(placementMetadataField)}
            config={placementConfig.get("start-date", Immutable.Map())}
            onChange={onChangeValueOrFallback}
            configLocation={["start-date"]}
            label="Start Date" />}
        {hasFields(availableFields, "Placement", "invoice-date") && <FieldPickerWithFallback
            options={getFieldOptionValueMaps(placementMetadataField)}
            config={placementConfig.get("invoice-date", Immutable.Map())}
            onChange={onChangeValueOrFallback}
            configLocation={["invoice-date"]}
            isClearable={true}
            label="Invoice Date" />}
      </div>
      {hasFields(availableFields, "Placement", "alternative-commission-mapping") && <AlternativeCommissionMapping
          config={placementConfig.get("alternative-commission-mapping")}
          fields={placementMetadataField}
          onChange={handleUpdateCommissionMapping}
      />}
      {hasFields(availableFields, "Placement", "additional-data") && <AdditionalData
          config={placementConfig.get("additional-data")}
          fields={placementMetadataField}
          onChange={handleUpdateAdditionalData}
      />}
    </div>
    <div style={{fontSize: "1.2rem", marginTop: 15}}><b>Perm</b></div>
    <FieldWithFallbackHeader />
    {hasFields(availableFields, "Placement", "employmentType", "perm", "salary") && <FieldPickerWithFallback
        options={getFieldOptionValueMaps(placementMetadataField)}
        config={placementConfig.getIn(["employmentType", "perm", "salary"], Immutable.Map())}
        onChange={onChangeValueOrFallback}
        configLocation={["employmentType", "perm", "salary"]}
        label="Salary" />}
    {hasFields(availableFields, "Placement", "employmentType", "perm", "fee-percentage") && <FieldPickerWithFallback
        options={getFieldOptionValueMaps(placementMetadataField)}
        config={placementConfig.getIn(["employmentType", "perm", "fee-percentage"], Immutable.Map())}
        onChange={onChangeValueOrFallback}
        configLocation={["employmentType", "perm", "fee-percentage"]}
        label="Fee Percentage" />}
    {hasFields(availableFields, "Placement", "employmentType", "perm", "fees") && <FieldPickerWithFallback
        options={getFieldOptionValueMaps(placementMetadataField)}
        config={placementConfig.getIn(["employmentType", "perm", "fees"], Immutable.Map())}
        onChange={onChangeValueOrFallback}
        configLocation={["employmentType", "perm", "fees"]}
        isClearable={true}
        label="Fees" />}
    <InputWithCheckbox
        fieldData={placementConfig.getIn(["employmentType", "perm"])}
        options={getOptionValueMaps(employmentTypesAvailableForPerm)}
        multiple={true}
        hasDisableCheckbox={true}
        hasCustomOptions={true}
        onChangeValue={onChangePermValue}
        onChangeCheckbox={onChangePermCheckbox}
        onAddCustomOption={onAddPermCustomOption}
        selectLabel="Perm Employment Types"
        checkboxLabel="Client does not work permanent roles"
        customOptionLabel="Add A Custom Perm Employment Type:" />
    {placementConfig.getIn(["employmentType", "perm", "isDisabled"]) &&
        <Warning message="Perm placement metrics will be hidden" />}
    <div style={{fontSize: "1.2rem", marginTop: 15}}><b>Contract</b></div>
    <FieldWithFallbackHeader />
    {hasFields(availableFields, "Placement", "end-date") && <FieldPickerWithFallback
        options={getFieldOptionValueMaps(placementMetadataField)}
        config={placementConfig.get("end-date", Immutable.Map())}
        onChange={onChangeValueOrFallback}
        configLocation={["end-date"]}
        label="End Date" />}
    {hasFields(availableFields, "Placement", "employmentType", "contract", "hours-per-day") && <FieldPickerWithFallback
        options={getFieldOptionValueMaps(placementMetadataField)}
        config={placementConfig.getIn(["employmentType", "contract", "hours-per-day"], Immutable.Map())}
        onChange={onChangeValueOrFallback}
        configLocation={["employmentType", "contract", "hours-per-day"]}
        isClearable={true}
        label="Hours per Day" />}
    {hasFields(availableFields, "Placement", "employmentType", "contract", "days-per-week") && <FieldPickerWithFallback
        options={getFieldOptionValueMaps(placementMetadataField)}
        config={placementConfig.getIn(["employmentType", "contract", "days-per-week"], Immutable.Map())}
        onChange={onChangeValueOrFallback}
        configLocation={["employmentType", "contract", "days-per-week"]}
        isClearable={true}
        label="Days per Week" />}
    {hasFields(availableFields, "Placement", "employmentType", "contract", "pay-rate") && <FieldPickerWithFallback
        options={getFieldOptionValueMaps(placementMetadataField)}
        config={placementConfig.getIn(["employmentType", "contract", "pay-rate"], Immutable.Map())}
        onChange={onChangeValueOrFallback}
        configLocation={["employmentType", "contract", "pay-rate"]}
        label="Pay Rate" />}
    {hasFields(availableFields, "Placement", "employmentType", "contract", "client-bill-rate") &&
        <FieldPickerWithFallback
            options={getFieldOptionValueMaps(placementMetadataField)}
            config={placementConfig.getIn(["employmentType", "contract", "client-bill-rate"], Immutable.Map())}
            onChange={onChangeValueOrFallback}
            configLocation={["employmentType", "contract", "client-bill-rate"]}
            label="Client Bill Rate" />}
    {hasFields(availableFields, "Placement", "employmentType", "contract", "burden-rate") && <FieldPickerWithFallback
        options={getFieldOptionValueMaps(placementMetadataField)}
        config={placementConfig.getIn(["employmentType", "contract", "burden-rate"], Immutable.Map())}
        onChange={onChangeValueOrFallback}
        configLocation={["employmentType", "contract", "burden-rate"]}
        isClearable={true}
        label="Burden Rate" />}
    <div style={{marginTop: 20, marginBottom: 15}}>
      {hasFields(availableFields, "Placement", "employmentType", "contract", "salary-unit", "field") &&
          <FieldPickerWithFallback
              options={getFieldOptionValueMaps(placementMetadataField)}
              config={placementConfig.getIn(["employmentType", "contract", "salary-unit", "field"], Immutable.Map())}
              onChange={onChangeValueOrFallback}
              configLocation={["employmentType", "contract", "salary-unit", "field"]}
              label="Salary Unit" />}
      {hasFields(availableFields, "Placement", "employmentType", "contract", "salary-unit", "mappings") &&
          <SalaryUnitTranslationPicker
              fieldData={placementConfig.getIn(
                  ["employmentType", "contract", "salary-unit", "mappings"],
                  Immutable.Map())}
              selectLabel="Salary Unit Mappings"
              onChangeValue={handleSalaryUnitMappingChange}
              onAddCustomOption={handleAddCustomSalaryUnit} />}
    </div>
    <InputWithCheckbox
        fieldData={placementConfig.getIn(["employmentType", "contract"])}
        options={getOptionValueMaps(employmentTypesAvailableForContract)}
        multiple={true}
        hasDisableCheckbox={true}
        hasCustomOptions={true}
        onChangeValue={onChangeContractValue}
        onChangeCheckbox={onChangeContractCheckbox}
        onAddCustomOption={onAddContractCustomOption}
        selectLabel="Contract Employment Types"
        checkboxLabel="Client does not work contract roles"
        customOptionLabel="Add A Custom Contract Employment Type:" />
    {placementConfig.getIn(["employmentType", "contract", "isDisabled"]) &&
        <Warning message="Contract placement metrics will be hidden" />}
    {hasFields(availableFields, "Placement", "employmentType", "contract", "reported-margin") && <div
        style={{
          marginBottom: 15,
          marginTop: 10
        }}>
      <div>
        Enable reported margin?
      </div>
      <div>
        <Toggle
            label={reportedMarginEnabled ? "Yes" : "No"}
            style={{width: 50, marginTop: 15, marginRight: 20, marginBottom: 10}}
            toggled={reportedMarginEnabled}
            onToggle={(e, isToggled) => handleReportedMarginToggle(isToggled)} />
        {reportedMarginEnabled && hasFields(availableFields, "Placement", "employmentType", "contract", "reported-margin-picker") && <div>
          <FieldWithFallbackHeader />
          <FieldPickerWithFallback
              options={getFieldOptionValueMaps(placementMetadataField)}
              config={placementConfig.getIn(["employmentType", "contract", "reported-margin"], Immutable.Map())}
              onChange={onChangeValueOrFallback}
              configLocation={["employmentType", "contract", "reported-margin"]}
              label={"Reported Margin"}
              isClearable={true}
              errorText={"Please complete this field or disable reported margin"}/>
        </div>}
      </div>
    </div>}
    {hasFields(availableFields, "Placement", "placement-scaling-factor") && <div
        style={{
          marginBottom: 15,
          marginTop: 10
        }}>
      <div>
        Are contract scaling factors used?
      </div>
      <div style={{display: "flex"}}>
        <Toggle
            label={hasScalingFactor ? "Yes" : "No"}
            style={{width: 50, marginTop: 15, marginRight: 20, marginBottom: 10}}
            toggled={hasScalingFactor}
            onToggle={(e, isToggled) => handleScalingFactorToggle(isToggled)} />
        {hasScalingFactor && <div style={{display: "flex"}}>
          <TextField
              value={scalingFactor}
              style={{width: 120, height: 60}}
              floatingLabelStyle={{top: 25}}
              inputStyle={{height: 55}}
              onChange={(o, value) => handleScalingFactorChange(value)}
              errorText={!isValidScalingFactor(scalingFactor) && "Provide a percentage between 0 and 100."}
              floatingLabelText="Scaling Factor" />
          <div style={{alignSelf: "center", marginTop: 15}}>%</div>
        </div>}
      </div>
    </div>}
    {hasFields(availableFields, "Placement", "copy-placement") && <div style={{marginBottom: 15, marginTop: 30}}>
      <div>
        Is Copy Placement used for Placement Extensions?
      </div>
      <div style={{display: "flex"}}>
        <Toggle
            label={usesCopyPlacement ? "Yes" : "No"}
            style={{width: 50, marginTop: 15, marginRight: 20, marginBottom: 10}}
            toggled={usesCopyPlacement}
            onToggle={(e, isToggled) => handleCopyPlacementToggle(isToggled)} />
        {usesCopyPlacement && <div style={{display: "flex"}}>
          <div style={{width: 350, marginRight: 15}}>
            <InputWithCheckbox
                fieldData={placementConfig.getIn(["copy-placement", "field"])}
                options={getOptionValueMaps(placementFields)}
                onChangeValue={value => handleCopyPlacementChange("field", value)}
                selectLabel="Placement ID Field" />
          </div>
          <div style={{width: 350}}>
            <InputWithCheckbox
                fieldData={placementConfig.getIn(["copy-placement", "previous-or-original"])}
                options={getOptionValueMaps(Immutable.List(["previous", "original"]))}
                onChangeValue={value => handleCopyPlacementChange("previous-or-original", value)}
                selectLabel="Is Previous or Original?" />
          </div>
        </div>}
      </div>
    </div>}

    {hasFields(availableFields, "Placement", "properties", "dateFieldToInfer") && <div
        style={{
          marginBottom: 15,
          marginTop: 30
        }}>
      <div>Infer Extension Date</div>
      <div style={{width: 250}}>
        <ImmutableSelect
            multi={false}
            clearable={false}
            selectedValue={placementConfig.getIn(["properties", "dateFieldToInfer", "value"])}
            onChange={selectedValue => onUpdatePlacement(placementConfig.setIn([
              "properties",
              "dateFieldToInfer",
              "value"], selectedValue))}
            options={inferExtensionDateOptions} />
      </div>
    </div>}
  </div>;
};

const JobOrderForm = ({
  productTier,
  jobOrderMetadataField,
  jobOrderConfig,
  onUpdateJobOrder,
  generateCurrencyDefaultText,
  currencyCodes,
  availableFields,
  showJobsPipelineWarnings
}) => {

  const priorityType = jobOrderConfig.getIn(["priority", "field", "value"]);

  const fieldNameToField = jobOrderMetadataField.reduce(
      (result, item) => result.set(item.get("name"), item),
      Immutable.Map());

  const getPriorityTypeOptions = (priorityType, fieldNameToField) => priorityType === "type" ?
      fieldNameToField.getIn([priorityType, "options"], Immutable.List())
          .map(type => type.set("label", type.get("value") + " (" + type.get("label") + ")"))
          .unshift(Immutable.Map({value: "0", label: "0"})) :
      getOptionValueMaps(fieldNameToField
          .getIn([priorityType, "options"], Immutable.List())
          .map(option => option.get("value")));


  const priorityTypeOptions = priorityType ? getPriorityTypeOptions(priorityType, fieldNameToField) : Immutable.List();
  const employmentTypeOptions = fieldNameToField.getIn(["employmentType", "options"], Immutable.List());

  const onChangeCurrencyValue = value => onUpdateJobOrder(jobOrderConfig.setIn(["currency", "field", "value"], value));
  const onChangeCurrencyCheckbox = () => handleCheckboxClick(
      jobOrderConfig,
      onUpdateJobOrder,
      Immutable.List(["currency", "field"]),
      false);

  const handleCurrencyChange = value => {
    onUpdateJobOrder(jobOrderConfig.setIn([
      "currency",
      "crm-currency-to-code",
      "value"], Immutable.List(value)));
  };

  const handleAddCustomCurrency = option => handleAddCustomOption(
      jobOrderConfig,
      onUpdateJobOrder,
      option,
      Immutable.List(["currency", "crm-currency-to-code"]),
      true);

  const onChangePriorityValue = value => {
    const newPriorityTypeOptions = fieldNameToField
        .getIn([value, "options"])
        .toOrderedSet()
        .toList()
        .map(option => option.get("value"));

    onUpdateJobOrder(jobOrderConfig
        .setIn(["priority", "field", "value"], value)
        .setIn(
            ["priority", "types", "value"],
            value === "type" ? addZeroOption(newPriorityTypeOptions) : newPriorityTypeOptions)
    );
  };

  const onChangePriorityCheckbox = () => {
    const isDisabled = jobOrderConfig.getIn(["priority", "field", "isDisabled"]);
    const updatedCheckboxField = jobOrderConfig
        .setIn(["priority", "field", "isDisabled"], !isDisabled)
        .setIn(["priority", "types", "isDisabled"], !isDisabled);
    if (!isDisabled) {
      onUpdateJobOrder(updatedCheckboxField
          .setIn(["priority", "field", "value"], null)
          .setIn(["priority", "types", "value"], Immutable.List()));
    } else {
      onUpdateJobOrder(updatedCheckboxField);
    }
  };

  const onChangePriorityTypes = value => onUpdateJobOrder(jobOrderConfig.setIn(
      ["priority", "types", "value"],
      Immutable.List(value)));
  const onAddPriorityTypeCustomOption = option => handleAddCustomOption(
      jobOrderConfig,
      onUpdateJobOrder,
      option,
      Immutable.List(["priority", "types"]),
      true);

  const [onChangePermEmploymentTypes, handleChangePermTypesCheckbox, onAddPermEmploymentTypeCustomOption] = generateOnChange(
      jobOrderConfig,
      onUpdateJobOrder,
      Immutable.List(["employmentType", "perm"]));

  const [onChangeContractEmploymentTypes, handleChangeContractTypesCheckbox, onAddContractEmploymentTypeCustomOption] = generateOnChange(
      jobOrderConfig,
      onUpdateJobOrder,
      Immutable.List(["employmentType", "contract"]));

  const remainingEmploymentTypes = employmentTypeOptions.filterNot(option =>
      jobOrderConfig
          .getIn(["employmentType", "contract", "value"], Immutable.List())
          .includes(option.get("value"))
      || jobOrderConfig
          .getIn(["employmentType", "perm", "value"], Immutable.List())
          .includes(option.get("value")));

  const areEmploymentTypesDisabled = jobOrderConfig.getIn(["employmentType", "contract", "isDisabled"]) &&
      jobOrderConfig.getIn(["employmentType", "perm", "isDisabled"]);

  const message = remainingEmploymentTypes.size > 0 && <div>
    {`Please note that the following ${remainingEmploymentTypes.size === 1
        ? "employment type has"
        : "employment types have"} not been mapped and therefore won't be shown in Jobs Pipeline -  `}<b>{remainingEmploymentTypes.map(
      t => t.get("label")).join(", ")}</b>
  </div>;

  const onChangeSalaryMin = field => onUpdateJobOrder(jobOrderConfig.setIn([
    "expected-value-fields",
    "salary-min-field",
    "value"], field));
  const onChangeSalaryMinCheckbox = () => handleCheckboxClick(
      jobOrderConfig,
      onUpdateJobOrder,
      Immutable.List(["expected-value-fields", "salary-min-field"]),
      false);

  const onChangeSalaryMax = field => onUpdateJobOrder(jobOrderConfig.setIn([
    "expected-value-fields",
    "salary-max-field",
    "value"], field));
  const onChangeSalaryMaxCheckbox = () => handleCheckboxClick(
      jobOrderConfig,
      onUpdateJobOrder,
      Immutable.List(["expected-value-fields", "salary-max-field"]),
      false);

  const onChangeSalaryLikely = field => onUpdateJobOrder(jobOrderConfig.setIn([
    "expected-value-fields",
    "salary-likely-field",
    "value"], field));
  const onChangeSalaryLikelyCheckbox = () => handleCheckboxClick(
      jobOrderConfig,
      onUpdateJobOrder,
      Immutable.List(["expected-value-fields", "salary-likely-field"]),
      false);

  const onChangeFeePercentage = field => onUpdateJobOrder(jobOrderConfig.setIn(
      ["expected-value-fields", "fee-percentage-field", "value"],
      field));
  const onChangeFeePercentageCheckbox = () => handleCheckboxClick(
      jobOrderConfig,
      onUpdateJobOrder,
      Immutable.List(["expected-value-fields", "fee-percentage-field"]),
      false);

  const onChangeValue = field => onUpdateJobOrder(jobOrderConfig.setIn([
    "expected-value-fields",
    "value-field",
    "value"], field));
  const onChangeValueCheckbox = () => handleCheckboxClick(
      jobOrderConfig,
      onUpdateJobOrder,
      Immutable.List(["expected-value-fields", "value-field"]),
      false);

  const handleUpdateAdditionalData = newAdditionalDataConfig => onUpdateJobOrder(jobOrderConfig.set(
      "additional-data",
      newAdditionalDataConfig));

  return <div>
    <div style={{paddingBottom: 20}}>
      <InputWithCheckbox
          fieldData={jobOrderConfig.getIn(["currency", "field"])}
          options={getFieldOptionValueMaps(jobOrderMetadataField)}
          hasDisableCheckbox={true}
          onChangeValue={onChangeCurrencyValue}
          onChangeCheckbox={onChangeCurrencyCheckbox}
          selectLabel="Currency Field"
          checkboxLabel={generateCurrencyDefaultText("JobOrder")}
      />
      {!jobOrderConfig.getIn(["currency", "field", "isDisabled"]) && <CurrencyCodeTranslationPicker
          fieldData={jobOrderConfig.getIn(["currency", "crm-currency-to-code"])}
          selectLabel="Currency Code Mappings"
          onChangeValue={handleCurrencyChange}
          onAddCustomOption={handleAddCustomCurrency}
          currencyCodes={currencyCodes}
      />}
    </div>
    {hasFields(availableFields, "JobOrder", "priority") && <InputWithCheckbox
        fieldData={jobOrderConfig.getIn(["priority", "field"])}
        options={getFieldOptionValueMaps(jobOrderMetadataField)}
        hasDisableCheckbox={true}
        onChangeValue={onChangePriorityValue}
        onChangeCheckbox={onChangePriorityCheckbox}
        selectLabel="Priority Field"
        checkboxLabel="Client does not track job order priorities"
    />}
    {hasFields(availableFields, "JobOrder", "priority") && <InputWithCheckbox
        fieldData={jobOrderConfig.getIn(["priority", "types"])}
        options={priorityTypeOptions}
        multiple={true}
        hasCustomOptions={true}
        onChangeValue={onChangePriorityTypes}
        onAddCustomOption={onAddPriorityTypeCustomOption}
        selectLabel="Priority Types"
        customOptionLabel="Add A Custom Priority Type:"
    />}
    {hasFields(availableFields, "JobOrder", "employmentType") &&
        <div style={{paddingTop: 20}}>
          <InputWithCheckbox
              fieldData={jobOrderConfig.getIn(["employmentType", "perm"], Immutable.Map())}
              options={employmentTypeOptions.filter(status => !jobOrderConfig.getIn([
                "employmentType",
                "contract", "value"], Immutable.List()).includes(status.get("value")))}
              multiple={true}
              hasDisableCheckbox={true}
              hasCustomOptions={true}
              onChangeValue={onChangePermEmploymentTypes}
              onChangeCheckbox={handleChangePermTypesCheckbox}
              onAddCustomOption={onAddPermEmploymentTypeCustomOption}
              selectLabel="Perm Employment Types"
              checkboxLabel="Client does not work permanent roles"
              customOptionLabel="Add A Custom Perm Employment Type:"
          />
          <InputWithCheckbox
              fieldData={jobOrderConfig.getIn(["employmentType", "contract"], Immutable.Map())}
              options={employmentTypeOptions.filter(status => !jobOrderConfig.getIn([
                "employmentType",
                "perm", "value"], Immutable.List()).includes(status.get("value")))}
              multiple={true}
              hasDisableCheckbox={true}
              hasCustomOptions={true}
              onChangeValue={onChangeContractEmploymentTypes}
              onChangeCheckbox={handleChangeContractTypesCheckbox}
              onAddCustomOption={onAddContractEmploymentTypeCustomOption}
              selectLabel="Contract Employment Types"
              checkboxLabel="Client does not work contract roles"
              customOptionLabel="Add A Custom Contract Employment Type:"
          />
          {showJobsPipelineWarnings && areEmploymentTypesDisabled &&
              <Warning message="As you have disabled all job order employment types the client will not have default Job Pipelines" />}
          {showJobsPipelineWarnings && !areEmploymentTypesDisabled && remainingEmploymentTypes.size > 0 && <Warning
              message={message} />}
        </div>}
    {hasFields(availableFields, "JobOrder", "expected-value-fields") && <div>
      <div style={{padding: "10px 0", fontWeight: "bold"}}>Expected Value Fields</div>
      <InputWithCheckbox
          fieldData={jobOrderConfig.getIn(["expected-value-fields", "salary-min-field"], Immutable.Map())}
          options={getFieldOptionValueMaps(jobOrderMetadataField)}
          hasDisableCheckbox={true}
          onChangeValue={onChangeSalaryMin}
          onChangeCheckbox={onChangeSalaryMinCheckbox}
          selectLabel="Salary Min Field"
          checkboxLabel="Client does not track minimum salary"
      />
      <InputWithCheckbox
          fieldData={jobOrderConfig.getIn(["expected-value-fields", "salary-max-field"], Immutable.Map())}
          options={getFieldOptionValueMaps(jobOrderMetadataField)}
          hasDisableCheckbox={true}
          onChangeValue={onChangeSalaryMax}
          onChangeCheckbox={onChangeSalaryMaxCheckbox}
          selectLabel="Salary Max Field"
          checkboxLabel="Client does not track maximum salary"
      />
      <InputWithCheckbox
          fieldData={jobOrderConfig.getIn(["expected-value-fields", "salary-likely-field"], Immutable.Map())}
          options={getFieldOptionValueMaps(jobOrderMetadataField)}
          hasDisableCheckbox={true}
          onChangeValue={onChangeSalaryLikely}
          onChangeCheckbox={onChangeSalaryLikelyCheckbox}
          selectLabel="Salary Likely Field"
          checkboxLabel="Client does not track likely salary"
      />
      <Warning
          message="The salary likely field will be used for expected value calculations by default.  If salary likely is not tracked then calculations will use the average of salary min and salary max." />
      <InputWithCheckbox
          fieldData={jobOrderConfig.getIn(["expected-value-fields", "fee-percentage-field"], Immutable.Map())}
          options={getFieldOptionValueMaps(jobOrderMetadataField)}
          hasDisableCheckbox={true}
          onChangeValue={onChangeFeePercentage}
          onChangeCheckbox={onChangeFeePercentageCheckbox}
          selectLabel="Fee Percentage Field"
          checkboxLabel="Client does not track fee percentage"
      />
      <InputWithCheckbox
          fieldData={jobOrderConfig.getIn(["expected-value-fields", "value-field"], Immutable.Map())}
          options={getFieldOptionValueMaps(jobOrderMetadataField)}
          hasDisableCheckbox={true}
          onChangeValue={onChangeValue}
          onChangeCheckbox={onChangeValueCheckbox}
          selectLabel="Value Field"
          checkboxLabel="Client does not track job order value"
      />
      <Warning message="If any of salary, fee percentage and value are not tracked but we have the other two then the third will be calculated as salary * fee percentage = value." />
    </div>}
    {productTier === productTiers.enterprise.enum && <AdditionalData
        config={jobOrderConfig.get("additional-data")}
        fields={jobOrderMetadataField}
        onChange={handleUpdateAdditionalData}
    />}
  </div>;
};

const OpportunityForm = ({
  productTier,
  opportunityMetadataField,
  opportunityConfig,
  onUpdateOpportunity,
  generateCurrencyDefaultText,
  currencyCodes
}) => {
  const onChangeCurrencyValue = value => onUpdateOpportunity(opportunityConfig.setIn(
      ["currency", "field", "value"],
      value));
  const onChangeCurrencyCheckbox = () => handleCheckboxClick(
      opportunityConfig,
      onUpdateOpportunity,
      Immutable.List(["currency", "field"]),
      false);

  const handleCurrencyChange = value => {
    onUpdateOpportunity(opportunityConfig.setIn([
      "currency",
      "crm-currency-to-code",
      "value"], Immutable.List(value)));
  };

  const handleAddCustomCurrency = option => handleAddCustomOption(
      opportunityConfig,
      onUpdateOpportunity,
      option,
      Immutable.List(["currency", "crm-currency-to-code"]),
      true);

  const handleUpdateAdditionalData = newAdditionalDataConfig => onUpdateOpportunity(opportunityConfig.set(
      "additional-data",
      newAdditionalDataConfig));

  return <div>
    <div style={{paddingBottom: 20}}>
      <InputWithCheckbox
          fieldData={opportunityConfig.getIn(["currency", "field"])}
          options={getFieldOptionValueMaps(opportunityMetadataField)}
          hasDisableCheckbox={true}
          onChangeValue={onChangeCurrencyValue}
          onChangeCheckbox={onChangeCurrencyCheckbox}
          selectLabel="Currency Field"
          checkboxLabel={generateCurrencyDefaultText("Opportunity")}
      />
      {!opportunityConfig.getIn(["currency", "field", "isDisabled"]) && <CurrencyCodeTranslationPicker
          fieldData={opportunityConfig.getIn(["currency", "crm-currency-to-code"])}
          selectLabel="Currency Code Mappings"
          onChangeValue={handleCurrencyChange}
          onAddCustomOption={handleAddCustomCurrency}
          currencyCodes={currencyCodes}
      />}
    </div>
    {productTier === productTiers.enterprise.enum && <AdditionalData
        config={opportunityConfig.get("additional-data")}
        fields={opportunityMetadataField}
        onChange={handleUpdateAdditionalData}
    />}
  </div>;
};

const JobSubmissionForm = ({
  productTier,
  jobSubmissionMetadataField,
  jobSubmissionConfig,
  onUpdateJobSubmission,
  availableFields,
  showJobsPipelineWarnings,
  availableCvSentAdditionalDataColumns
}) => {

  const fieldNameToField = jobSubmissionMetadataField.reduce(
      (result, item) => result.set(item.get("name"), item),
      Immutable.Map());

  const jobSubmissionStatuses = fieldNameToField.getIn(["status", "options"], Immutable.List());

  const [onChangeShortlisted, onChangeShortlistedCheckbox, onAddShortlistedCustomOption] = generateOnChange(
      jobSubmissionConfig,
      onUpdateJobSubmission,
      Immutable.List(["cv-sent", "type", "shortlist-types"]));

  const [onChangeRejected, onChangeRejectedCheckbox, onAddRejectedCustomOption] = generateOnChange(
      jobSubmissionConfig,
      onUpdateJobSubmission,
      Immutable.List(["cv-sent", "type", "reject-types"]));

  const [onChangeOffer, onChangeOfferCheckbox, onAddOfferCustomOption] = generateOnChange(
      jobSubmissionConfig,
      onUpdateJobSubmission,
      Immutable.List(["offer", "types"]));

  const shortlistedPredicate = v => !jobSubmissionConfig
      .getIn(["cv-sent", "type", "shortlist-types", "value"], Immutable.List())
      .includes(v.get("value"));
  const rejectedPredicate = v => !jobSubmissionConfig
      .getIn(["cv-sent", "type", "reject-types", "value"], Immutable.List())
      .includes(v.get("value"));
  const offeredPredicate = v => !jobSubmissionConfig
      .getIn(["offer", "types", "value"], Immutable.List())
      .includes(v.get("value"));

  const handleUpdateCvSentAdditionalData = newAdditionalDataConfig => onUpdateJobSubmission(jobSubmissionConfig.setIn([
    "cv-sent",
    "additional-data"], newAdditionalDataConfig));
  const showCvSentAdditionalData = !(jobSubmissionConfig.getIn(["cv-sent", "type", "shortlist-types", "isDisabled"])
          && jobSubmissionConfig.getIn(["cv-sent", "type", "reject-types", "isDisabled"]))
      && productTier === productTiers.enterprise.enum;
  const handleUpdateOfferAdditionalData = newAdditionalDataConfig => onUpdateJobSubmission(jobSubmissionConfig.setIn([
    "offer",
    "additional-data"], newAdditionalDataConfig));
  const showOfferAdditionalData = !jobSubmissionConfig.getIn(["offer", "types", "isDisabled"])
      && productTier
      === productTiers.enterprise.enum;

  return <div>
    <div style={{marginTop: 20, marginBottom: 15}}><b>-> cube19 CV Sents</b></div>
    <InputWithCheckbox
        fieldData={jobSubmissionConfig.getIn(["cv-sent", "type", "shortlist-types"], Immutable.Map())}
        options={jobSubmissionStatuses.filter(rejectedPredicate).filter(offeredPredicate)}
        multiple={true}
        hasDisableCheckbox={true}
        hasCustomOptions={true}
        onChangeValue={onChangeShortlisted}
        onChangeCheckbox={onChangeShortlistedCheckbox}
        onAddCustomOption={onAddShortlistedCustomOption}
        selectLabel="Statuses for Shortlisted Candidates"
        checkboxLabel="Client does not track shortlisted candidates"
        customOptionLabel="Add a custom Shortlisted Candidate status"
    />
    {jobSubmissionConfig.getIn(["cv-sent", "type", "shortlist-types", "isDisabled"]) &&
        <Warning
            message={`Shortlisted candidate metrics will be hidden${showJobsPipelineWarnings
                ? " and the shortlist stage will be removed from jobs pipeline"
                : ""}`}
        />}
    <InputWithCheckbox
        fieldData={jobSubmissionConfig.getIn(["cv-sent", "type", "reject-types"], Immutable.Map())}
        options={jobSubmissionStatuses.filter(shortlistedPredicate).filter(offeredPredicate)}
        multiple={true}
        hasDisableCheckbox={true}
        hasCustomOptions={true}
        onChangeValue={onChangeRejected}
        onChangeCheckbox={onChangeRejectedCheckbox}
        onAddCustomOption={onAddRejectedCustomOption}
        selectLabel="Statuses for Rejected Candidates"
        checkboxLabel="Client does not track rejected candidates"
        customOptionLabel="Add a custom Rejected Candidate status"
    />
    {jobSubmissionConfig.getIn(["cv-sent", "type", "reject-types", "isDisabled"]) &&
        <Warning
            message={`Rejected candidate metrics will be hidden${showJobsPipelineWarnings
                ? " and the rejected stage will be removed from jobs pipeline"
                : ""}`}
        />}

    {showCvSentAdditionalData && <AdditionalData
        config={jobSubmissionConfig.getIn(["cv-sent", "additional-data"])}
        fields={jobSubmissionMetadataField}
        onChange={handleUpdateCvSentAdditionalData}
        availableColumns={availableCvSentAdditionalDataColumns}
    />}

    {hasFields(availableFields, "JobSubmission", "offer") && <>
      <div style={{marginTop: 20, marginBottom: 15}}><b>-> cube19 Offers</b></div>
      <InputWithCheckbox
          fieldData={jobSubmissionConfig.getIn(["offer", "types"], Immutable.Map())}
          options={jobSubmissionStatuses.filter(shortlistedPredicate).filter(rejectedPredicate)}
          multiple={true}
          hasDisableCheckbox={true}
          hasCustomOptions={true}
          onChangeValue={onChangeOffer}
          onChangeCheckbox={onChangeOfferCheckbox}
          onAddCustomOption={onAddOfferCustomOption}
          selectLabel="Statuses for Offers"
          checkboxLabel="Client does not track offers"
          customOptionLabel="Add a custom Offer status"
      />
      {jobSubmissionConfig.getIn(["offer", "types", "isDisabled"]) &&
          <Warning
              message={`Offer metrics will be hidden${showJobsPipelineWarnings
                  ? " and the offer stage will be removed from jobs pipeline"
                  : ""}`}
          />}
    </>}
    {showOfferAdditionalData && <AdditionalData
        config={jobSubmissionConfig.getIn(["offer", "additional-data"])}
        fields={jobSubmissionMetadataField}
        onChange={handleUpdateOfferAdditionalData}
    />}
  </div>;
};

const AppointmentToInterviewsForm = ({
  appointmentConfig,
  onUpdateAppointment,
  appointmentMetadataField,
  productTier
}) => {

  const fieldNameToField = appointmentMetadataField.reduce(
      (result, item) => result.set(item.get("name"), item),
      Immutable.Map());

  const optionTypesInUse = appointmentConfig
      .getIn(["meeting", "action", "candidate-types", "value"], Immutable.List())
      .concat(appointmentConfig
          .getIn(["meeting", "action", "client-types", "value"], Immutable.List()));

  const appointmentTypeOptions = fieldNameToField
      .getIn([appointmentConfig.getIn(["type", "field", "value"]), "options"], Immutable.List())
      .filter(o => !optionTypesInUse.includes(o.get("value")));

  const handleTypeChange = value => onUpdateAppointment(appointmentConfig
      .setIn(["type", "field", "value"], value)
      .setIn(["type", "firstTypes", "value"], Immutable.List())
      .setIn(["type", "furtherTypes", "value"], Immutable.List())
  );

  const [handleFirstTypesChange, handleChangeFirstTypesCheckbox, handleAddCustomFirstType] = generateOnChange(
      appointmentConfig,
      onUpdateAppointment,
      Immutable.List(["type", "firstTypes"]));

  const [handleFurtherTypesChange, handleChangeFurtherTypesCheckbox, handleAddCustomFurtherType] = generateOnChange(
      appointmentConfig,
      onUpdateAppointment,
      Immutable.List(["type", "furtherTypes"]));

  const handleUpdateAdditionalData = newAdditionalDataConfig => onUpdateAppointment(appointmentConfig.set(
      "additional-data",
      newAdditionalDataConfig));

  const showAdditionalData = !(appointmentConfig.getIn(["type", "furtherTypes", "isDisabled"])
          && appointmentConfig.getIn(["type", "firstTypes", "isDisabled"]))
      && productTier === productTiers.enterprise.enum;

  return <div>
    <InputWithCheckbox
        fieldData={appointmentConfig.getIn(["type", "field"])}
        options={getFieldOptionValueMaps(appointmentMetadataField)}
        onChangeValue={handleTypeChange}
        selectLabel="Appointment Type Field"
    />
    <div style={{marginTop: 20, marginBottom: 15}}><b>-> cube19 Interviews</b></div>
    <InputWithCheckbox
        fieldData={appointmentConfig.getIn(["type", "firstTypes"])}
        options={appointmentTypeOptions.filter(status => !appointmentConfig.getIn([
          "type",
          "furtherTypes",
          "value"]).includes(status.get("value")))}
        multiple={true}
        hasDisableCheckbox={true}
        hasCustomOptions={true}
        onChangeValue={handleFirstTypesChange}
        onChangeCheckbox={handleChangeFirstTypesCheckbox}
        onAddCustomOption={handleAddCustomFirstType}
        selectLabel="First Interview Types"
        customOptionLabel="Add A Custom 1st Interview Type:"
        checkboxLabel="Client does not use appointments to track first interviews"
    />
    {appointmentConfig.getIn(["type", "firstTypes", "isDisabled"]) && <Warning
        message="First interview metrics will be hidden"
    />}
    <InputWithCheckbox
        fieldData={appointmentConfig.getIn(["type", "furtherTypes"])}
        options={appointmentTypeOptions.filter(status => !appointmentConfig.getIn([
          "type",
          "firstTypes",
          "value"]).includes(status.get("value")))}
        multiple={true}
        hasDisableCheckbox={true}
        hasCustomOptions={true}
        onChangeValue={handleFurtherTypesChange}
        onChangeCheckbox={handleChangeFurtherTypesCheckbox}
        onAddCustomOption={handleAddCustomFurtherType}
        selectLabel="Further Interview Types"
        customOptionLabel="Add A Custom Further Interview Type:"
        checkboxLabel="Client does not use appointments to track further interviews"
    />
    {appointmentConfig.getIn(["type", "furtherTypes", "isDisabled"]) && <Warning
        message="Further interview metrics will be hidden"
    />}
    {showAdditionalData && <AdditionalData
        config={appointmentConfig.get("additional-data")}
        fields={appointmentMetadataField}
        onChange={handleUpdateAdditionalData}
    />}
  </div>;
};

const AppointmentToMeetings = ({
  appointmentConfig,
  noteConfig,
  onUpdateAppointment,
  appointmentMetadataField,
  productTier
}) => {

  const appointmentFieldNameToField = appointmentMetadataField.reduce(
      (result, item) => result.set(item.get("name"), item),
      Immutable.Map());

  const optionTypesInUse = appointmentConfig
      .getIn(["type", "firstTypes", "value"], Immutable.List())
      .concat(appointmentConfig
          .getIn(["type", "furtherTypes", "value"], Immutable.List()));

  const appointmentTypes = appointmentFieldNameToField
      .getIn(["type", "options"], Immutable.List())
      .filter(o => !optionTypesInUse.includes(o.get("value")));

  const [handleCandidateTypesChange, handleChangeCandidateTypesCheckbox, handleAddCustomCandidateType] = generateOnChange(
      appointmentConfig,
      onUpdateAppointment,
      Immutable.List(["meeting", "action", "candidate-types"]));

  const [handleClientTypesChange, handleChangeClientTypesCheckbox, handleAddCustomClientType] = generateOnChange(
      appointmentConfig,
      onUpdateAppointment,
      Immutable.List(["meeting", "action", "client-types"]));

  const handleUpdateAdditionalData = newAdditionalDataConfig => onUpdateAppointment(appointmentConfig.setIn([
    "meeting",
    "additional-data"], newAdditionalDataConfig));
  const showAdditionalData = !(appointmentConfig.getIn(["meeting", "action", "candidate-types", "isDisabled"])
          && appointmentConfig.getIn(["meeting", "action", "client-types", "isDisabled"]))
      && productTier === productTiers.enterprise.enum;

  return <div>
    <div style={{marginTop: 20, marginBottom: 15}}><b>-> cube19 Meetings</b></div>
    <div>
      <InputWithCheckbox
          options={appointmentTypes.filter(status => !appointmentConfig.getIn([
            "meeting",
            "action",
            "client-types",
            "value"]).includes(status.get("value")))}
          fieldData={appointmentConfig.getIn(["meeting", "action", "candidate-types"], Immutable.Map())}
          multiple={true}
          hasDisableCheckbox={true}
          hasCustomOptions={true}
          onChangeValue={handleCandidateTypesChange}
          onChangeCheckbox={handleChangeCandidateTypesCheckbox}
          onAddCustomOption={handleAddCustomCandidateType}
          selectLabel="Candidate Meeting Types"
          checkboxLabel="Client does not use appointments to track candidate meetings"
          customOptionLabel="Add A Custom Candidate Meeting Type:"
      />
      {appointmentConfig.getIn(["meeting", "action", "candidate-types", "isDisabled"]) &&
          noteConfig.getIn(["meeting", "action", "candidate-types", "isDisabled"]) &&
          <Warning
              message="As both appointment and note candidate meeting types are disabled candidate meetings metrics will be hidden"
          />}
      <InputWithCheckbox
          options={appointmentTypes.filter(status => !appointmentConfig.getIn([
            "meeting",
            "action",
            "candidate-types",
            "value"]).includes(status.get("value")))}
          fieldData={appointmentConfig.getIn(["meeting", "action", "client-types"], Immutable.Map())}
          multiple={true}
          hasDisableCheckbox={true}
          hasCustomOptions={true}
          onChangeValue={handleClientTypesChange}
          onChangeCheckbox={handleChangeClientTypesCheckbox}
          onAddCustomOption={handleAddCustomClientType}
          selectLabel="Client Meeting Types"
          checkboxLabel="Client does not use appointments to track client meetings"
          customOptionLabel="Add A Custom Client Meeting Type:"
      />
      {appointmentConfig.getIn(["meeting", "action", "client-types", "isDisabled"]) &&
          noteConfig.getIn(["meeting", "action", "client-types", "isDisabled"]) &&
          <Warning
              message="As both appointment and note client meeting types are disabled client meetings metrics will be hidden"
          />}
    </div>
    {showAdditionalData && <AdditionalData
        config={appointmentConfig.getIn(["meeting", "additional-data"])}
        fields={appointmentMetadataField}
        onChange={handleUpdateAdditionalData}
    />}
  </div>;
};

const NoteToCallsForm = ({noteConfig, onUpdateNote, noteMetadataField, productTier}) => {
  const fieldNameToField = noteMetadataField.reduce(
      (result, item) => result.set(item.get("name"), item),
      Immutable.Map());

  const alreadyUsedOptions = noteConfig
      .getIn(["cvSent", "action", "types", "value"], Immutable.List())
      .concat(noteConfig.getIn(["meeting", "action", "candidate-types", "value"], Immutable.List()))
      .concat(noteConfig.getIn(["meeting", "action", "client-types", "value"], Immutable.List()));

  const actionTypeOptions = fieldNameToField
      .getIn([noteConfig.getIn(["call", "action", "field", "value"]), "options"], Immutable.List())
      .filter(o => !alreadyUsedOptions.includes(o.get("value")));

  const handleActionFieldChange = value => onUpdateNote(noteConfig
      .setIn(["call", "action", "field", "value"], value)
      .setIn(["call", "action", "types", "value"], Immutable.List())
  );

  const handleActionTypeChange = value => onUpdateNote(noteConfig.setIn(
      ["call", "action", "types", "value"],
      Immutable.List(value)));

  const handleAddCustomActionType = value => handleAddCustomOption(
      noteConfig,
      onUpdateNote,
      value,
      Immutable.List(["call", "action", "types"]),
      true);

  const handleUpdateAdditionalData = newAdditionalDataConfig => onUpdateNote(noteConfig.setIn([
    "call",
    "additional-data"], newAdditionalDataConfig));

  return <div>
    <InputWithCheckbox
        options={getFieldOptionValueMaps(noteMetadataField)}
        fieldData={noteConfig.getIn(["call", "action", "field"])}
        onChangeValue={handleActionFieldChange}
        selectLabel="Action Type Field"
    />
    <div style={{marginTop: 20, marginBottom: 15}}><b>-> cube19 Calls</b></div>
    <InputWithCheckbox
        options={actionTypeOptions}
        fieldData={noteConfig.getIn(["call", "action", "types"])}
        multiple={true}
        hasCustomOptions={true}
        onChangeValue={handleActionTypeChange}
        onAddCustomOption={handleAddCustomActionType}
        selectLabel="Action Types"
        customOptionLabel="Add A Custom Action Type Option:"
    />
    {productTier === productTiers.enterprise.enum && <AdditionalData
        config={noteConfig.getIn(["call", "additional-data"])}
        fields={noteMetadataField}
        onChange={handleUpdateAdditionalData}
    />}
  </div>;
};

const NoteToCVSentForm = ({
  noteConfig,
  noteMetadataField,
  onUpdateNote,
  productTier,
  availableAdditionalDataColumns
}) => {

  const fieldNameToField = noteMetadataField.reduce(
      (result, item) => result.set(item.get("name"), item),
      Immutable.Map());

  const alreadyUsedOptions = noteConfig
      .getIn(["call", "action", "types", "value"], Immutable.List())
      .concat(noteConfig.getIn(["meeting", "action", "candidate-types", "value"], Immutable.List()))
      .concat(noteConfig.getIn(["meeting", "action", "client-types", "value"], Immutable.List()));

  const actionTypeOptions = fieldNameToField
      .getIn([noteConfig.getIn(["call", "action", "field", "value"]), "options"], Immutable.List())
      .filter(o => !alreadyUsedOptions.includes(o.get("value")));

  const [handleActionTypeChange, handleChangeActionTypesCheckbox, handleAddCustomActionType] = generateOnChange(
      noteConfig,
      onUpdateNote,
      Immutable.List(["cvSent", "action", "types"]));

  const handleUpdateAdditionalData = newAdditionalDataConfig => onUpdateNote(noteConfig.setIn([
    "cvSent",
    "additional-data"], newAdditionalDataConfig));
  const showAdditionalData = !noteConfig.getIn(["cvSent", "action", "types", "isDisabled"])
      && productTier
      === productTiers.enterprise.enum;

  return <div>
    <div style={{marginTop: 20, marginBottom: 15}}><b>-> cube19 Spec CV Sent</b></div>
    <InputWithCheckbox
        options={actionTypeOptions}
        fieldData={noteConfig.getIn(["cvSent", "action", "types"], Immutable.Map())}
        multiple={true}
        hasDisableCheckbox={true}
        hasCustomOptions={true}
        onChangeValue={handleActionTypeChange}
        onChangeCheckbox={handleChangeActionTypesCheckbox}
        onAddCustomOption={handleAddCustomActionType}
        selectLabel="Action Types"
        checkboxLabel="Client does not use notes to track spec CVs sent"
        customOptionLabel="Add A Custom CV Sent Action Type:"
    />
    {showAdditionalData && <AdditionalData
        config={noteConfig.getIn(["cvSent", "additional-data"])}
        fields={noteMetadataField}
        onChange={handleUpdateAdditionalData}
        availableColumns={availableAdditionalDataColumns}
    />}
  </div>;
};

const NoteToMeetings = ({noteConfig, appointmentConfig, onUpdateNote, noteMetadataField, productTier}) => {

  const fieldNameToField = noteMetadataField.reduce(
      (result, item) => result.set(item.get("name"), item),
      Immutable.Map());

  const alreadyUsedOptions = noteConfig
      .getIn(["cvSent", "action", "types", "value"], Immutable.List())
      .concat(noteConfig.getIn(["call", "action", "types", "value"], Immutable.List()));

  const actionTypeOptions = fieldNameToField
      .getIn([noteConfig.getIn(["call", "action", "field", "value"]), "options"], Immutable.List())
      .map(option => option.get("value"))
      .filter(option => !alreadyUsedOptions.includes(option));

  const availableOptionsForCandidate = actionTypeOptions.filter(option => !noteConfig
      .getIn(["meeting", "action", "client-types", "value"], Immutable.List())
      .includes(option)
  );

  const availableOptionsForClient = actionTypeOptions.filter(option => !noteConfig
      .getIn(["meeting", "action", "candidate-types", "value"], Immutable.List())
      .includes(option)
  );

  const [handleCandidateTypesChange, handleChangeCandidateTypesCheckbox, handleAddCustomCandidateType] = generateOnChange(
      noteConfig,
      onUpdateNote,
      Immutable.List(["meeting", "action", "candidate-types"]));

  const [handleClientTypesChange, handleChangeClientTypesCheckbox, handleAddCustomClientType] = generateOnChange(
      noteConfig,
      onUpdateNote,
      Immutable.List(["meeting", "action", "client-types"]));

  const handleUpdateAdditionalData = newAdditionalDataConfig => onUpdateNote(noteConfig.setIn([
    "meeting",
    "additional-data"], newAdditionalDataConfig));
  const showAdditionalData = !(noteConfig.getIn(["meeting", "action", "candidate-types", "isDisabled"])
          && noteConfig.getIn(["meeting", "action", "client-types", "isDisabled"]))
      && productTier === productTiers.enterprise.enum;

  return <div>
    <div style={{marginTop: 20, marginBottom: 15}}><b>-> cube19 Meetings</b></div>
    <InputWithCheckbox
        options={getOptionValueMaps(availableOptionsForCandidate)}
        fieldData={noteConfig.getIn(["meeting", "action", "candidate-types"], Immutable.Map())}
        multiple={true}
        hasDisableCheckbox={true}
        hasCustomOptions={true}
        onChangeValue={handleCandidateTypesChange}
        onChangeCheckbox={handleChangeCandidateTypesCheckbox}
        onAddCustomOption={handleAddCustomCandidateType}
        selectLabel="Candidate Meeting Types"
        checkboxLabel="Client does not use notes to track candidate meetings"
        customOptionLabel="Add A Custom Candidate Meeting Type:"
    />
    {appointmentConfig.getIn(["meeting", "action", "candidate-types", "isDisabled"]) &&
        noteConfig.getIn(["meeting", "action", "candidate-types", "isDisabled"]) &&
        <Warning
            message="As both appointment and note candidate meeting types are disabled candidate meetings metrics will be hidden"
        />}
    <InputWithCheckbox
        options={getOptionValueMaps(availableOptionsForClient)}
        fieldData={noteConfig.getIn(["meeting", "action", "client-types"], Immutable.Map())}
        multiple={true}
        hasDisableCheckbox={true}
        hasCustomOptions={true}
        onChangeValue={handleClientTypesChange}
        onChangeCheckbox={handleChangeClientTypesCheckbox}
        onAddCustomOption={handleAddCustomClientType}
        selectLabel="Client Meeting Types"
        checkboxLabel="Client does not use notes to track client meetings"
        customOptionLabel="Add A Custom Client Meeting Type:"
    />
    {appointmentConfig.getIn(["meeting", "action", "client-types", "isDisabled"]) &&
        noteConfig.getIn(["meeting", "action", "client-types", "isDisabled"]) &&
        <Warning
            message="As both appointment and note client meeting types are disabled client meetings metrics will be hidden"
        />}
    {showAdditionalData && <AdditionalData
        config={noteConfig.getIn(["meeting", "additional-data"])}
        fields={noteMetadataField}
        onChange={handleUpdateAdditionalData}
    />}
  </div>;
};

const CandidateForm = ({candidateConfig, candidateMetadataField, onUpdateCandidate, entityFieldConfig}) => {
  const availableAdditionalDataColumns = getAvailableAdditionalDataFields(
      Immutable.List([["additional-data", "value"]]),
      candidateConfig,
      "CANDIDATE",
      entityFieldConfig);
  const handleUpdateAdditionalData = newAdditionalDataConfig => onUpdateCandidate(candidateConfig.set(
      "additional-data",
      newAdditionalDataConfig));
  return <div>
    <AdditionalData
        config={candidateConfig.get("additional-data")}
        fields={candidateMetadataField}
        onChange={handleUpdateAdditionalData}
        availableColumns={availableAdditionalDataColumns}
    />
  </div>;
};

const CorporateUserForm = ({corporateUserConfig, corporateUserMetadataField, onUpdateCorporateUser}) => {
  const handleUpdateAdditionalData = newAdditionalDataConfig => onUpdateCorporateUser(corporateUserConfig.set(
      "additional-data",
      newAdditionalDataConfig));
  return <div>
    <AdditionalData
        config={corporateUserConfig.get("additional-data")}
        fields={corporateUserMetadataField}
        onChange={handleUpdateAdditionalData}
    />
  </div>;
};

const LeadForm = ({leadConfig, leadMetadataField, onUpdateLead}) => {
  const handleUpdateAdditionalData = newAdditionalDataConfig => onUpdateLead(leadConfig.set(
      "additional-data",
      newAdditionalDataConfig));
  return <div>
    <AdditionalData
        config={leadConfig.get("additional-data")}
        fields={leadMetadataField}
        onChange={handleUpdateAdditionalData}
    />
  </div>;
};

const AdditionalDataForm = ({config, metadataField, onUpdate}) => {
  const handleUpdateAdditionalData = newAdditionalDataConfig => onUpdate(config.set(
      "additional-data",
      newAdditionalDataConfig));
  return <div>
    <AdditionalData
        config={config.get("additional-data")}
        fields={metadataField}
        onChange={handleUpdateAdditionalData}
    />
  </div>;
};

const TaskForm = ({taskConfig, taskMetadataField, onUpdateTask}) => {

  const fieldNameToField = taskMetadataField.reduce(
      (result, item) => result.set(item.get("name"), item),
      Immutable.Map());

  const actionTypeOptions = fieldNameToField
      .getIn([taskConfig.getIn(["call", "action", "field", "value"]), "options"], Immutable.List())
      .toOrderedSet();

  const handleActionFieldChange = value => onUpdateTask(taskConfig
      .setIn(["call", "action", "field", "value"], value)
      .setIn(["call", "action", "types", "value"], Immutable.List())
  );

  const [handleActionTypeChange, handleChangeClientTypesCheckbox, handleAddCustomActionType] = generateOnChange(
      taskConfig,
      onUpdateTask,
      Immutable.List(["call", "action", "types"]));


  const handleUpdateAdditionalData = newAdditionalDataConfig => onUpdateTask(taskConfig.set(
      "additional-data",
      newAdditionalDataConfig));
  return <div>
    <InputWithCheckbox
        options={getFieldOptionValueMaps(taskMetadataField)}
        fieldData={taskConfig.getIn(["call", "action", "field"])}
        onChangeValue={handleActionFieldChange}
        selectLabel="Action Type Field"
    />
    <div style={{marginTop: 20, marginBottom: 15}}><b>-> cube19 Calls</b></div>
    <InputWithCheckbox
        options={actionTypeOptions}
        fieldData={taskConfig.getIn(["call", "action", "types"])}
        multiple={true}
        hasDisableCheckbox={true}
        hasCustomOptions={true}
        onChangeValue={handleActionTypeChange}
        onChangeCheckbox={handleChangeClientTypesCheckbox}
        onAddCustomOption={handleAddCustomActionType}
        selectLabel="Action Types"
        checkboxLabel="Client does not use Tasks to track Calls"
        customOptionLabel="Add A Custom Action Type Option:"
    />
    <AdditionalData
        config={taskConfig.get("additional-data")}
        fields={taskMetadataField}
        onChange={handleUpdateAdditionalData}
    />
  </div>;
};


const FieldPicker = ({options, onChange, selectedValue}) => {
  const [searchString, setSearchString] = React.useState("");
  return <ImmutableSelect
      options={options}
      closeMenuOnSelect={true}
      clearable={false}
      onChange={onChange}
      selectedValue={selectedValue}
      multi={false}
      inputValue={searchString}
      onInputChange={(value, detail) => {
        const action = detail.action;
        if (action === "input-change") {
          setSearchString(value);
        } else if (action === "menu-close") {
          setSearchString("");
        }
      }}
  />;
};

const AdditionalData = ({config, fields, onChange, availableColumns = Immutable.List()}) => {
  const handleChange = (value, inputField, index) => {
    onChange(config.setIn(["value", index, inputField], value));
  };

  const additionalDataMappings = config.get("value", Immutable.List()) || Immutable.List();

  const addNewRow = () => {
    const columnToAssign = availableColumns.first();
    onChange(config.update("value", Immutable.List(), ad => ad.push(Immutable.Map(
        columnToAssign ? {"ge-column-data": columnToAssign} : {}
    ))));
  };

  const removeRow = index => {
    onChange(config.update("value", Immutable.List(), ad => ad.delete(index)));
  };

  const selectedValues = additionalDataMappings.map(adm => adm.get("from"));
  const options = fields.filter(field => !selectedValues.includes(field.get("name")));

  const fieldNameToField = indexBy(field => field.get("name"), fields);

  return <div>
    Additional Data
    <IconButton
        iconClassName="fa fa-plus"
        disabled={additionalDataMappings.size >= 5}
        onClick={addNewRow}
        iconStyle={{color: additionalDataMappings.size >= 5 ? "lightgray" : "gray", fontSize: "1.3rem"}} />
    {additionalDataMappings.size >= 5 &&
        <span style={{color: "rgba(255, 122, 122, 1)"}}>Max number of mappings reached</span>}
    {additionalDataMappings.size > 0 && <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10
        }}>
      <div style={{width: 250}}>Field</div>
      <div style={{width: 250}}>Label</div>
      <div style={{width: 250}}>PII Category</div>
      <div style={{width: 48}}></div>
    </div>}
    {additionalDataMappings.map((additionalDataMapping, index) => {

      const optionsWithSelectedValue = additionalDataMapping.get("from")
          ? options.push(fieldNameToField.get(additionalDataMapping.get("from")))
          : options;

      return <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}>
        <div style={{width: 250}}>
          <FieldPicker
              options={getFieldOptionValueMaps(optionsWithSelectedValue)}
              onChange={value => handleChange(value, "from", index)}
              selectedValue={additionalDataMapping.get("from")}
          />
        </div>
        <div style={{width: 250}}>
          <TextField
              style={{alignSelf: "center"}}
              value={additionalDataMapping.getIn(["to"], "")}
              onChange={(e, value) => handleChange(value, "to", index)}
          />
        </div>
        <div style={{width: 250}}>
          <ImmutableSelect
              multi={false}
              placeholder={"PII Category"}
              selectedValue={additionalDataMapping.getIn(["pii"], null)}
              onChange={piiCategory => handleChange(piiCategory, "pii", index)}
              options={piiCategories} />
        </div>

        <IconButton
            iconClassName="fa fa-times"
            disabled={false}
            onClick={() => removeRow(index)}
            iconStyle={{color: "gray", fontSize: "1.3rem"}} />

      </div>;
    })}
    <div
        style={{color: "rgba(255, 122, 122, 1)", fontSize: "0.8rem", marginTop: 5, marginBottom: 10}}>
      {config.get("hasErrors") && "Please ensure all fields are filled for each additional data row "}
    </div>
  </div>;
};

const AlternativeCommissionMapping = ({config, fields, onChange}) => {
  const handleChange = (value, inputField, index) => {
    onChange(config.setIn(["value", index, inputField], value));
  };

  const fieldList = fields.map(field => field.get("name"));
  const commissionMappings = config.get("value", Immutable.List()) || Immutable.List();

  const addNewRow = () => {
    onChange(config.update("value", Immutable.List(), ad => ad.push(Immutable.Map())));
  };

  const removeRow = index => {
    onChange(config.update("value", Immutable.List(), ad => ad.delete(index)));
  };

  const selectedValues = commissionMappings.map(adm => adm.get("from"));
  const options = fieldList.filter(field => !selectedValues.includes(field));

  const handleFieldReferenceToggle = (isToggled, index) => {
    onChange(config
        .setIn(["value", index, "is-field-reference"], isToggled)
        .setIn(["value", index, "percent"], null)
    );
  };

  return <div>
    Alternative Placement Commission Mapping
    <IconButton
        iconClassName="fa fa-plus"
        disabled={commissionMappings.size >= 5}
        onClick={addNewRow}
        iconStyle={{color: commissionMappings.size >= 5 ? "lightgray" : "gray", fontSize: "1.3rem"}} />
    {commissionMappings.size >= 20 &&
        <span style={{color: "rgba(255, 122, 122, 1)"}}>Max number of mappings reached</span>}
    {commissionMappings.size > 0 && <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10
        }}>
      <div style={{width: 250}}>Commission Recipient User ID Field</div>
      <div style={{width: 250}}>Commission Percentage</div>
      <div style={{width: 250}}>Commission Recipient Role Type</div>
      <div style={{width: 48}}></div>
    </div>}
    {commissionMappings.map((mapping, index) => <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}>
      <div style={{width: 250}}>
        <FieldPicker
            options={getOptionValueMaps(options.push(mapping.getIn(["user-id-field"])))}
            onChange={value => handleChange(value, "user-id-field", index)}
            selectedValue={mapping.get("user-id-field")}
        />
      </div>
      <div style={{display: "flex"}}>
        <Toggle
            label={mapping.get("is-field-reference") ? "Field" : "Value"}
            style={{width: 50, marginTop: 15, marginRight: 20, marginBottom: 10}}
            toggled={mapping.get("is-field-reference")}
            onToggle={(e, isToggled) => handleFieldReferenceToggle(isToggled, index)}
        />
        <div style={{width: 250}}>
          {mapping.get("is-field-reference") ? <FieldPicker
                  options={getOptionValueMaps(options.push(mapping.getIn(["percent"])))}
                  onChange={value => handleChange(value, "percent", index)}
                  selectedValue={mapping.get("percent")}
              /> :
              <TextField
                  errorText={!isValidCommissionPercentage(mapping.get("percent"))
                      && "Please provide a value between 0.1 and 1"}
                  style={{alignSelf: "center"}}
                  value={mapping.get("percent", "")}
                  onChange={(e, value) => handleChange(value, "percent", index)}
              />}
        </div>
      </div>
      <div style={{width: 250}}>
        <TextField
            style={{alignSelf: "center"}}
            value={mapping.get("type", "")}
            onChange={(e, value) => handleChange(value, "type", index)}
        />
      </div>

      <IconButton
          iconClassName="fa fa-times"
          disabled={false}
          onClick={() => removeRow(index)}
          iconStyle={{color: "gray", fontSize: "1.3rem"}} />

    </div>)}
    <div
        style={{color: "rgba(255, 122, 122, 1)", fontSize: "0.8rem", marginTop: 5, marginBottom: 10}}>
      {config.get("hasErrors") && "Please ensure all fields are filled correctly for each row"}
    </div>
  </div>;
};

const GeneralForm = ({
  config,
  onUpdateProperties,
  entityFieldConfig,
  users,
  userBulkStatus,
  availableFields
}) => {

  const userOptions = users
      .map(user => {
        const userEmailStr = user.get("email") ? " - " + user.get("email") : "";
        return Immutable.Map({
          value: user.get("crm-id"),
          label: user.get("full-name") + " - " + user.get("crm-id") + userEmailStr
        });
      })
      .sortBy(user => user.get("label").toLowerCase());

  const onChangeExcludedUsers = value => onUpdateProperties(config.setIn(
      ["exclude-data-for-user-ids", "value"],
      value));

  const getExcludeUserDataComponent = () => {
    if (userBulkStatus === "FAILED") {
      return <Warning message="User Bulk Failed" />;
    } else if (userBulkStatus === "COMPLETED" && users.size > 0) {
      return <InputWithCheckbox
          options={userOptions}
          fieldData={config.get("exclude-data-for-user-ids", Immutable.Map())}
          multiple={true}
          onChangeValue={onChangeExcludedUsers}
      />;
    } else {
      return <div style={{paddingBottom: 15}}>
        Awaiting User Bulk...
        <CircularProgress size={20} style={{paddingLeft: 10, marginTop: 10}} />
      </div>;
    }
  };

  const dataVisibilityOptions = Immutable.fromJS([
    {
      "value": "ALL",
      "label": "Everyone"
    },
    {
      "value": "SELF",
      "label": "Themselves Only"
    },
    {
      "value": "GROUP_AND_BELOW",
      "label": "Their Group"
    }
  ]);

  const splitDeskOptions = Immutable.fromJS([
    {
      value: "record",
      label: "Just the record owner"
    },
    {
      value: "record-and-job",
      label: "The record and job owner"
    }
  ]);

  const onChangeLinkedOwners = value => onUpdateProperties(config.setIn(["activity-owners", "value"], value));

  const meetingDateOptions = entityFieldConfig
      .getIn(["MEETING", "dateFields"], Immutable.List())
      .map(dateField => Immutable.Map({value: dateField, label: dateField.get("label")}));
  const interviewDateOptions = entityFieldConfig
      .getIn(["INTERVIEW", "dateFields"], Immutable.List())
      .map(dateField => Immutable.Map({value: dateField, label: dateField.get("label")}));

  return <div>
    <div style={{paddingBottom: 5}}><b>Exclude Data For Users</b></div>
    <div style={{fontSize: "0.9rem", marginBottom: 10}}>Please list any users whose data should not be displayed in
      cube19 e.g. training users, internal recruitment users, etc.
    </div>
    {getExcludeUserDataComponent()}
    {hasFields(availableFields, "Properties", "default-user-template") && <>
      <div style={{paddingBottom: 5, paddingTop: 15}}><b>Default User Template</b></div>
      <InputWithCheckbox
          selectLabel="Users can see"
          options={dataVisibilityOptions}
          fieldData={config.getIn(["default-user-template", "data-visibility"], Immutable.Map())}
          onChangeValue={value => onUpdateProperties(config.setIn(
              ["default-user-template", "data-visibility", "value"],
              value))}
      />
      <div style={{display: "flex"}}><Checkbox
          checked={config.getIn(["default-user-template", "ip-restricted", "value"], false)}
          style={{marginLeft: 20, width: 20, marginTop: 10}}
          inputStyle={{pointerEvents: "inherit"}}
          onCheck={() => onUpdateProperties(config.setIn(
              ["default-user-template", "ip-restricted", "value"],
              !config.getIn(["default-user-template", "ip-restricted", "value"])))} />
        <div style={{fontSize: "1rem", maxWidth: 300, marginTop: 13}}>{"Users should be IP restricted"}</div>
      </div>
    </>}
    {hasFields(availableFields, "Properties", "activity-owners") && <>
      <div style={{paddingBottom: 5, paddingTop: 15}}><b>Who should recruitment activity be credited to?</b></div>
      <InputWithCheckbox
          options={splitDeskOptions}
          fieldData={config.get("activity-owners", Immutable.Map())}
          onChangeValue={onChangeLinkedOwners}
      />
    </>}
    {hasFields(availableFields, "Properties", "metric-dates") && <>
      <div style={{paddingBottom: 5, paddingTop: 15}}><b>Metric Dates</b></div>
      <InputWithCheckbox
          selectLabel="Which date should be used for Interview Metrics?"
          options={interviewDateOptions}
          fieldData={config.getIn(["metric-dates", "interview"], Immutable.Map())}
          onChangeValue={value => onUpdateProperties(config.setIn(["metric-dates", "interview", "value"], value))}
      />
      <InputWithCheckbox
          selectLabel="Which date should be used for Meeting Metrics?"
          options={meetingDateOptions}
          fieldData={config.getIn(["metric-dates", "meeting"], Immutable.Map())}
          onChangeValue={value => onUpdateProperties(config.setIn(["metric-dates", "meeting", "value"], value))}
      />
    </>}
  </div>;
};

const CustomOptionInput = ({label, handleAddOption, currentOptions, isDisabled, doubleValueInput, mapToOptions}) => {

  const [isOpen, setIsOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(doubleValueInput ? Immutable.Map() : null);
  const isDuplicate = currentOptions.includes(inputValue);

  const handleClose = () => {
    setIsOpen(false);
    setInputValue(doubleValueInput ? Immutable.Map() : null);
  };

  const onAddClick = () => {
    handleAddOption(inputValue);
    setIsOpen(false);
    setInputValue(doubleValueInput ? Immutable.Map() : null);
  };

  return <div>
    <div style={{marginLeft: 5}}>
      <IconButton
          iconClassName="fa fa-plus"
          disabled={isDisabled}
          onClick={() => setIsOpen(true)}
          iconStyle={{color: isDisabled ? "lightgray" : "gray", fontSize: "1.3rem"}} />
    </div>
    <Dialog
        contentStyle={{width: 500}}
        bodyStyle={{overflowY: "visible"}}
        onRequestClose={handleClose}
        open={isOpen}>
      <div style={{display: "flex", flexDirection: "column"}}>
        <div style={{alignSelf: "center"}}>{label}</div>
        {doubleValueInput ? <DoubleValueInput
            inputValue={inputValue}
            setInputValue={setInputValue}
            options={mapToOptions}
        /> : <SingleValueInput
            label={label}
            inputValue={inputValue}
            setInputValue={setInputValue}
            isDuplicate={isDuplicate} />}
        <div style={{display: "flex", justifyContent: "flex-end", marginTop: 20, fontSize: "0.9rem"}}>
          <RaisedButton style={{marginRight: 20}} onClick={handleClose}>Cancel</RaisedButton>
          <RaisedButton disabled={isDuplicate} primary={true} onClick={onAddClick}>Add</RaisedButton>
        </div>
      </div>
    </Dialog>
  </div>;
};

const SingleValueInput = ({label, isDuplicate, inputValue, setInputValue}) => <TextField
    style={{alignSelf: "center"}}
    id={label}
    errorText={isDuplicate && inputValue + " already exists in dropdown"}
    value={inputValue || ""}
    onChange={(e, value) => setInputValue(value)}
/>;

const DoubleValueInput = ({inputValue, setInputValue, options}) => {

  const [searchString, setSearchString] = React.useState("");

  return <div style={{display: "flex", marginLeft: 20}}>
    <TextField
        id="from"
        floatingLabelText="From"
        floatingLabelFixed={true}
        floatingLabelStyle={{color: "rgba(0, 0, 0, 0.5)"}}
        style={{marginRight: 10, width: 180}}
        value={inputValue.get("from") || ""}
        onChange={(e, value) => setInputValue(valueMap => valueMap.set("from", value))}
    />
    <div style={{width: 40, alignSelf: "center", marginTop: 20}}>➜</div>
    <div style={{width: "120px", alignSelf: "center"}}>
      <div
          style={{
            color: "rgba(0, 0, 0, 0.5)",
            fontSize: "0.8rem",
            marginTop: 10,
            marginLeft: 2,
            marginBottom: 2
          }}>To
      </div>
      <div style={{width: 180}}>
        <ImmutableSelect
            options={options}
            multi={false}
            clearable={true}
            onChange={value => setInputValue(valueMap => valueMap.set("to", value))}
            selectedValue={inputValue.get("to") || ""}
            inputValue={searchString}
            onInputChange={(value, detail) => {
              const action = detail.action;
              if (action === "input-change") {
                setSearchString(value);
              } else if (action === "menu-close") {
                setSearchString("");
              }
            }}
        />
      </div>
    </div>
  </div>;
};

const InputWithCheckbox = ({
  fieldData,
  onAddCustomOption,
  options,
  hasDisableCheckbox = false,
  selectLabel,
  checkboxLabel,
  multiple = false,
  hasCustomOptions = false,
  customOptionLabel,
  onChangeValue,
  onChangeCheckbox,
  doubleValueCustomInput = false,
  optionGenerator = getOptionValueMaps,
  mapToOptions,
  maxValues = 20
}) => {
  const optionValues = options.map(o => o.get("value")).toSet();
  const customOptionsWithoutDups = optionGenerator(fieldData.get("customOptions", Immutable.List()))
      .filter(customOption => !optionValues.includes(customOption.get("value")));

  const availableOptions = options
      // NOTE: filtering out 'Please Select' which often comes through in client metadata from BH.
      .filter(o => o && o.get("value") && !o.get("value").toString().toLowerCase().includes("please select"))
      .concat(customOptionsWithoutDups);
  const hasErrors = fieldData.get("hasErrors");
  const errorText = hasDisableCheckbox
      ? "Please complete this field or tick the checkbox to indicate it should not be included"
      : "Please complete this field";

  const [searchString, setSearchString] = React.useState("");

  const maxValuesReached = multiple && maxValues && (fieldData.get("value", Immutable.List()).size >= maxValues);
  return <div>
    <div style={{marginBottom: 5}}>
      {selectLabel}
    </div>
    <div style={{display: "flex"}}>
      <div style={{width: 700}}>
        <ImmutableSelect
            isOptionDisabled={() => maxValuesReached}
            options={availableOptions}
            closeMenuOnSelect={!multiple}
            clearable={false}
            disabled={fieldData.get("isDisabled")}
            onChange={value => onChangeValue(value)}
            selectedValueOrValues={fieldData.get("value", Immutable.List())}
            multi={multiple}
            inputValue={searchString}
            onInputChange={(value, detail) => {
              const action = detail.action;
              if (action === "input-change") {
                setSearchString(value);
              } else if (action === "menu-close") {
                setSearchString("");
              }
            }}
        />
        <div style={{fontSize: "0.8rem", marginTop: 5, marginBottom: 10}}>
          {maxValuesReached && "Max number of mappings reached"}
        </div>
        <div style={{color: "rgba(255, 122, 122, 1)", fontSize: "0.8rem", marginTop: 5, marginBottom: 10}}>
          {hasErrors && errorText}
        </div>
      </div>
      {hasCustomOptions && <CustomOptionInput
          currentOptions={availableOptions.map(o => o.get("value"))}
          isDisabled={fieldData.get("isDisabled") || maxValuesReached}
          doubleValueInput={doubleValueCustomInput}
          handleAddOption={value => onAddCustomOption(value)}
          mapToOptions={mapToOptions}
          label={customOptionLabel} />}
      {hasDisableCheckbox && <div style={{display: "flex"}}><Checkbox
          checked={fieldData.get("isDisabled")}
          inputStyle={{pointerEvents: "inherit"}}
          style={{marginLeft: 20, width: 20, marginTop: 10}}
          onCheck={onChangeCheckbox} />
        <div style={{fontSize: "0.8rem", maxWidth: 300, marginTop: 14}}>{checkboxLabel}</div>
      </div>}
    </div>
  </div>;
};

export const Warning = ({message}) => <div
    style={{
      backgroundColor: "rgba(252, 229, 169, 0.8)",
      color: "rgba(51, 49, 44, 0.8)",
      borderRadius: 5,
      display: "flex",
      padding: 10,
      marginTop: 5,
      paddingBottom: 10,
      marginBottom: 15
    }}>
  <i
      style={{
        color: "rgba(252, 229, 169, 0.8)",
        backgroundColor: "rgba(51, 49, 44, 0.8)",
        fontSize: "0.8rem",
        padding: "4px 8px",
        alignSelf: "center",
        height: 20,
        marginRight: 10,
        borderRadius: 10
      }}
      className="fa fa-exclamation"
  />
  <div>{message}</div>
</div>;

const getFieldOptionValueMaps = options => options
    ? options
        .toOrderedSet()
        .toList()
        .map(o => {
          const name = o.get("name");
          const label = o.get("label");
          const formattedLabel = label.toLowerCase() === name.toLowerCase() ? label : label + " (" + name + ")";
          return Immutable.Map({label: formattedLabel, value: name});
        })
    : Immutable.List();

const getOptionValueMaps = options => options
    ? options
        .toOrderedSet()
        .toList()
        .map(o => Immutable.Map({label: o, value: o}))
    : Immutable.List();

const mappingOptionGenerator = options => options
    .map(o => Immutable.Map({value: o, label: o.get("from") + " -> " + o.get("to")}));

const CurrencyCodeTranslationPicker = ({fieldData, selectLabel, onChangeValue, onAddCustomOption, currencyCodes}) => {
  const options = mappingOptionGenerator(defaultCurrencyMappings);

  const currencyOptions = currencyCodes
      .sortBy(c => c.get("code"))
      .map(c => Immutable.Map({value: c.get("code"), label: c.get("code")}));

  return <InputWithCheckbox
      fieldData={fieldData}
      options={options}
      selectLabel={selectLabel}
      multiple={true}
      hasCustomOptions={true}
      onChangeValue={onChangeValue}
      onAddCustomOption={onAddCustomOption}
      doubleValueCustomInput={true}
      customOptionLabel="Add A Custom Currency Mapping"
      optionGenerator={mappingOptionGenerator}
      mapToOptions={currencyOptions}
      maxValues={50}
  />;
};

const SalaryUnitTranslationPicker = ({fieldData, selectLabel, onChangeValue, onAddCustomOption}) => {
  const options = mappingOptionGenerator(defaultSalaryUnitMappings);

  const mapToOptions = salaryUnitOptions
      .map(su => Immutable.Map({value: su, label: su}));

  return <InputWithCheckbox
      fieldData={fieldData}
      options={options}
      selectLabel={selectLabel}
      multiple={true}
      hasCustomOptions={true}
      onChangeValue={onChangeValue}
      onAddCustomOption={onAddCustomOption}
      doubleValueCustomInput={true}
      customOptionLabel="Add A Custom Salary Unit Mapping"
      optionGenerator={mappingOptionGenerator}
      mapToOptions={mapToOptions}
      maxValues={50}
  />;
};

const FieldPickerWithFallback = ({
  options,
  config,
  configLocation,
  label,
  onChange,
  isClearable = false,
  errorText = "Please complete this field or clear fallback"}) => {

  const [searchString, setSearchString] = React.useState("");
  const [fallbackSearchString, setFallbackSearchString] = React.useState("");
  const hasErrors = config.get("hasErrors");

  const onChangeValue = value => onChange(configLocation.concat(["value", "field"]), value);
  const onChangeFallback = value => onChange(configLocation.concat(["value", "fallback"]), value);

  return <div style={{display: "flex", marginBottom: 10}}>
    <div style={{marginTop: 10, marginBottom: 20, marginRight: 10, width: 120}}>
      {label}
    </div>
    <div style={{width: 300, marginRight: 20}}>
      <ImmutableSelect
          options={options}
          closeMenuOnSelect={true}
          clearable={isClearable}
          onChange={onChangeValue}
          selectedValue={config.getIn(["value", "field"])}
          multi={false}
          inputValue={searchString}
          onInputChange={(value, detail) => {
            const action = detail.action;
            if (action === "input-change") {
              setSearchString(value);
            } else if (action === "menu-close") {
              setSearchString("");
            }
          }}
      />
      <div style={{color: "rgba(255, 122, 122, 1)", fontSize: "0.8rem", marginTop: 5, marginBottom: 10}}>
        {hasErrors && errorText}
      </div>
    </div>
    <div style={{width: 300}}>
      <ImmutableSelect
          options={options}
          closeMenuOnSelect={true}
          clearable={true}
          onChange={onChangeFallback}
          selectedValue={config.getIn(["value", "fallback"])}
          multi={false}
          inputValue={fallbackSearchString}
          onInputChange={(value, detail) => {
            const action = detail.action;
            if (action === "input-change") {
              setFallbackSearchString(value);
            } else if (action === "menu-close") {
              setFallbackSearchString("");
            }
          }}
      />
    </div>
  </div>;
};

export {
  PlacementForm,
  OpportunityForm,
  JobOrderForm,
  JobSubmissionForm,
  AppointmentToInterviewsForm,
  NoteToCallsForm,
  NoteToCVSentForm,
  AppointmentToMeetings,
  NoteToMeetings,
  CandidateForm,
  CorporateUserForm,
  LeadForm,
  TaskForm,
  AdditionalDataForm,
  AdditionalData,
  GeneralForm
};
